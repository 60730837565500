import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import ResponsiveLayout from "../components/layout/responsive-layout"
import { exactDate } from "../services/date"

const AuditPage: FunctionComponent = () => {
  const [updateTime, setUpdateTime] = useState("")
  const [torrents, setTorrents] = useState([])

  const fetchTorrents = useCallback(async () => {
    const response = await Promise.resolve(
      fetch("https://blog.smartlike.org/audit/")
        .then(res => res.text())
        .catch(error => {
          console.log("json parsing error occured ", error)
          return null
        })
    )
    if (response) {
      const t = response.split(",")
      let r = []
      let lastUpdateTime = ""
      for (var i = 0; i < t.length; i++) {
        const p = t[i].split(".")
        if (p.length == 4) {
          const pp = p[0].split("-")
          if (pp.length == 6) {
            r.push({
              name: t[i],
              url: "https://blog.smartlike.org/audit/" + t[i],
              comment: "MD5: " + pp[5],
            })
            lastUpdateTime = pp[1] + "-" + pp[2] + "-" + pp[3]
          } else if (pp.length == 5) {
            r.push({
              name: t[i],
              url: "https://blog.smartlike.org/audit/" + t[i],
              comment: "MD5: " + pp[4],
            })
            lastUpdateTime = pp[1] + "-" + pp[2] + "-" + pp[3]
          }
        }
      }
      r.reverse()
      setTorrents(r)
      setUpdateTime(lastUpdateTime)
      //setUpdateTime(Math.floor(response[response.length - 1].ts / 1000))
    }
  }, [])

  useEffect(() => {
    fetchTorrents()
  }, [])

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ResponsiveLayout>
        {{
          content: (
            <div style={{ fontFamily: "Roboto", marginBottom: "20px" }}>
              {updateTime != "" && (
                <>
                  <div className="header">Data</div>
                  <div className="intro">
                    The only user data stored on Smartlike are anonymous
                    transactions published by users. All data is kept public.
                    Sensitive parts like direct messages and subscriptions for
                    device synchronization are end-to-end encrypted by users.
                    <br />
                    Transactions are published on BitTorrent during beta.
                    <span> Last updated on {updateTime}.</span>
                  </div>

                  <div>
                    <table style={{ margin: "auto", width: "90%" }}>
                      {torrents.length != 0 && (
                        <thead style={{ fontWeight: 400, textAlign: "center" }}>
                          <tr>
                            <td>{/*volume*/}</td>
                            {/*<td>checksum</td>*/}
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {torrents.map(c => (
                          <tr>
                            <td>
                              <a href={c.url}>{c.name}</a>
                            </td>
                            {/*<td className="mono">{c.comment}</td>*/}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          ),
          sidebar: null,
        }}
      </ResponsiveLayout>
      <style jsx>
        {`
            .header {
                text-align: center;
                font-size: 18px;
                margin-bottom: 10px;                
            }
          td {
            padding: 3px;
          }
          .intro {
            margin-bottom: 20px;
            line-height: 22px;
            /*text-align: justify;*/
            padding-left: 5px;
            padding-right: 5px;
        }

          .mono {
              font-family: monospace;
              font-size: 14px;
        `}
      </style>
    </>
  )
}
export default AuditPage
